//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet,TextInput } from 'react-native';
import { colors } from '../../styles/colors';
import { verticalScale2 } from '../../styles/Scaling';
import { textStyles } from '../../styles/textFontStyles';

// create a component
const CustomTextInput = (props) => {
    return (

        <View
            style={{
            flexDirection: 'row',
            width: '98%',
            backgroundColor: '#FFF',
            height: verticalScale2(54),
            alignItems: 'center',
            borderColor: '#d9d9d9',
            borderRadius: 10,
            marginTop: verticalScale2(10),
            justifyContent: 'center',
            borderColor: colors.border_color,
            borderWidth : 1,
            }}>

        <View
        style={{
            flex: 1,
            //maxWidth:100
        }}>
        <TextInput
            style={[
            textStyles.InterMedium,
            {
                //maxWidth:150,
                color: 'rgba(153, 153, 153, 1)',
                textAlign: 'left',
                fontSize: verticalScale2(15),
                marginLeft: 0,
                borderColor: colors.border_color,
                borderRadius: 12,
                paddingLeft: 10,
               // fontWeight: '500',
                placeholderTextColor: 'rgba(153, 153, 153, 1)'
            },
            ]}
            onChangeText={value => {props.onChangeText(value)}}
            placeholder={props.placeholder}
           // fontStyle ={'Inter-Medium'}
        //    placeholderTextColor={'rgba(153, 153, 153, 1)'}
            value={props.value}
            maxLength={8}
            returnKeyType={'done'}
            keyboardType="number-pad"
            onSubmitEditing={() => {
          //  ref_phoneNumberInput.current.focus();

            }}
            blurOnSubmit={false}
        />
        </View>
    </View>
    );
};

// define your styles
const styles = StyleSheet.create({

});

//make this component available to the app
export default CustomTextInput;
