// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../usr/local/lib/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../usr/local/lib/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./app/assets/fonts/IBMPlexSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./app/assets/fonts/IBMPlexSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./app/assets/fonts/IBMPlexSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./app/assets/fonts/Inter-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./app/assets/fonts/Inter-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./app/assets/fonts/Inter-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./app/assets/fonts/Inter-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./app/assets/fonts/Inter-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'IBM Plex Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'IBMPlexSans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'IBMPlexSans-Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'IBMPlexSans-Bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'IBMPlexSans-Medium';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'Inter-Light';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'Inter-Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'Inter-Medium';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'Inter-SemiBold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + "); /* IE9 Compat Modes */\n}\n@font-face {\n  font-family: 'Inter-Bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + "); /* IE9 Compat Modes */\n}\nbody {\n  font-family: Inter;\n  /* overflow: hidden; */\n}\n/* Hide scrollbar for Chrome, Safari and Opera */\nbody::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\nbody {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\ntextarea:focus,\ninput:focus {\n  outline: none;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\n", ""]);
// Exports
module.exports = exports;
